@import "../../App.scss";

.WhatsNew {
    background-color: #EBEEF0;
    padding: 32px 16px 16px;
    min-height: calc(100vh - 56px - 83px);

    >h2 {
        font-family: $helveticalight;
        font-size: 32px;
        letter-spacing: 1.6px;
        color: $darkblue;
        margin-bottom: 24px;
        font-weight: normal;
    }

    .new-incentives-container {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto;
        row-gap: 16px;
        column-gap: 24px;
    }
}

@media screen and (min-width: 481px) {
    .WhatsNew {
        .new-incentives-container {
            grid-template-columns: 1fr 1fr;
        }
    }
}