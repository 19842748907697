@import '../../App.scss';

.TierDetails {
	display: grid;
    grid-template-columns: auto;

    .icon {
        display: block;
    }

    .large-2 {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .heading-wrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;
        align-items: baseline;

        h1 {
            
        }

        .disclaimer {
            font-family: $inter;
            font-size: 12px;
            color: #617380;
            line-height: 24px;
        }
    }
    

    .ContentLightBlueWrapper {
        width: 100%;
        overflow: hidden;
    }

    .mobile-disclaimer-container {
        display: flex;
        justify-content: flex-end;

        .mobile-disclaimer {
            font-family: $inter;
            font-size: 12px;
            color: #617380;
            line-height: 24px;
            margin-bottom: 8px;
        }
    }

    .details-container {
        display: grid;
        grid-template-columns: 127px 2fr 3fr 1fr;
        grid-template-rows: 56px auto;
        grid-template-columns: 127px 254px 381px 127px;
        max-width: 100%;
        overflow-x: scroll;

        .top {
            background-color: #FFFFFF;
            color: #1C2E3B;
            font-weight: 600;
            font-size: 14px;
            line-height: 1;
            font-family: $inter;
            padding-left: 21px;
            height: 100%;
            display: flex;
            align-items: center;
        }

        .tier {
            min-height: 127px;
            display: flex;
            flex-direction: column;
            padding-left: 17px;
            padding-right: 17px;
            padding-top: 24px;
            background-color: #F7F9FA;
            border-right: 1px solid #EBEEF0;
            border-top: 1px solid #EBEEF0;
            text-align: center;
            font-family: $inter;
            font-weight: 600;
            color: #1C2E3B;
            font-size: 14px;
            line-height: 1;

            .icon {
                margin-bottom: 12px;
            }

            &.first {
                border-top: none;
            }

            &.last {
                color: #FFFFFF;
                background-color: #1C2E3B;
                border-right: 1px solid #0E181F;
                border-top: none;
            }
        }

        .eligibility {
            padding-top: 32px;
            padding-bottom: 32px;
            padding-left: 24px;
            padding-right: 24px;
            font-family: $inter;
            font-size: 16px;
            line-height: 24px;
            color: #617380;
            background-color: #F7F9FA;
            border-right: 1px solid #EBEEF0;
            border-top: 1px solid #EBEEF0;
            display: flex;
            flex-direction: column;
            width: 100%;

            strong {
                color: #1C2E3B;
                font-weight: 600;
                display: inline-block;
            }

            small {
                font-size: 12px;
                line-height: 24px;
                margin-top: auto;
            }

            &.first {
                border-top: none;
            }

            &.last {
                background-color: #1C2E3B;
                color: #9AABB8;
                border-right: 1px solid #0E181F;
                border-top: none;

                strong {
                    color: #FFFFFF;
                }
            }
        }

        .rewards {
            padding-top: 32px;
            padding-bottom: 32px;
            padding-left: 24px;
            padding-right: 24px;
            color: #617380;
            font-family: $inter;
            font-size: 14px;
            line-height: 20px;
            background-color: #F7F9FA;
            border-right: 1px solid #EBEEF0;
            border-top: 1px solid #EBEEF0;

            &.wide {
                grid-column: 3 / 5;
            }

            &.first {
                border-top: none;
            }

            &.last {
                color: #9AABB8;
                border-top: none;
                background-color: #1C2E3B;
            }
        }

        .next {
            padding-top: 32px;
            padding-bottom: 32px;
            padding-left: 24px;
            padding-right: 24px;
            font-family: $inter;
            font-weight: 500;
            color: #1C2E3B;
            line-height: 1;
            background-color: #F7F9FA;
            border-top: 1px solid #EBEEF0;

            span {
                margin-top: 8px;
                font-weight: 600;
                line-height: 32px;
            }

            &.first {
                border-top: none;
            }
        }
    }
}

@media screen and (min-width: 900px) {

    .TierDetails {

        .ContentLightBlueWrapper {
            width: 100%;
            overflow: auto;
        }

        grid-template-columns: 204px auto;

        .mobile-disclaimer-container {
            display: none;
        }

        .details-container {
            grid-template-columns: 127px 2fr 3fr 1fr;
            grid-template-rows: 56px auto;
            overflow-x: auto;
        }
    }
}